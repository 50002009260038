$enable-flex: true;

// Farben
$blue: #0181c4; // #0081c3
$brand-primary: #0181c4;

$input-btn-line-height: 1.35;

// Font Awesome 5
$fa-font-path: '../fonts/FontAwesome5';
$fa-css-prefix: fa;
