// Artikelliste
.artikel {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  //grid-auto-rows: minmax(15rem, auto);
  grid-row-gap: 1rem;
  margin-bottom: 2rem;

  &-grid {
    &--2 {
      grid-template-columns: repeat(2, 1fr);

      .artikel__name {
        max-width: 240px;
      }

      figure {
        height: 366px;
      }
    }

    &--3 {
      grid-template-columns: repeat(3, 1fr);

      .artikel__name {
        max-width: 180px;
      }

      figure {
        height: 243px;
      }
    }

    &--4 {
      grid-template-columns: repeat(4, 1fr);

      .artikel__name {
        max-width: 140px;
      }

      figure {
        height: 182px;
      }
    }

    &--6 {
      grid-template-columns: repeat(6, 1fr);

      .artikel__name {
        max-width: 80px;
      }

      figure {
        height: 121px;
      }
    }
  }

  // Artikelname
  .list-group {
    .artikel__name {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  // Bilder
  figure {
    overflow-y: auto;
    margin: 0;
  }
}
