html {
  height: 100%;
}

body {
  background: #fff;
  color: #222;
  margin-top: 5rem;
  height: calc(100% - 5rem);
}

// grid
.grid {
  display: grid;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;
}

.container {
  height: 100%;
  display: grid;
  grid-template-areas:
    'header header'
    'tree content'
    'footer footer';
  grid-template-columns:
    /* 1 */
    auto
    /* 2 */
    1fr;
  grid-template-rows:
    /* 1 */
    auto
    /* 2 */
    1fr
    /* 3 */
    auto;

  h1 {
    grid-column: -1/1;
  }

  header {
    grid-area: header;
  }

  main {
    grid-area: content;
    margin-bottom: 1rem;
    height: calc(100% - 6rem);
    align-content: flex-start;

    // Kollektionen
    .grid & {
      display: grid;
      grid-column-gap: 0.5rem;
      grid-row-gap: 1rem;
    }

    .grid--2-4 & {
      grid-template-columns: 2fr 4fr;
    }

    // Pager, Breadcrumb
    nav {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  footer {
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
  }
}

aside {
  min-width: 30wv;
}

.footer {
  padding: 0.5rem 0;
}

// Formulare
.form-control {
  margin-right: 0.2rem;
}

.form-group {
  .form-control,
  .btn,
  .chosen-container {
    margin-right: 0.2rem;
  }

  .chosen-container-single {
    a.chosen-single {
      height: inherit;
    }
  }

  input,
  a.btn {
    line-height: 1.4;
  }
}

fieldset {
  padding-bottom: 0.2em;

  label {
    margin-right: 0.2rem;
  }
}

.filter {
  margin-bottom: 1rem;
}

// Aktionen-Links mit Hand-Kursor
a {
  td.d-flex &,
  .card-footer & {
    cursor: pointer;
    color: $link-color !important;
  }

  td.d-flex & {
    min-width: 1.6rem;
  }
}

// Unterkategorien-Formular
.grid {
  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &--2-4 {
    .container {
      grid-template-rows: auto 1fr auto;
    }
  }

  &--2-3 {
    grid-template-columns: 2fr 3fr;
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);

    div:first-child {
      display: flex;
      align-items: flex-end;
    }
  }
}

// Tables
.tableFloatingHeaderOriginal {
  background: #fff;
}

// Helpers
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.pagination {
  margin: 1rem auto;
}

// Icons
.fa {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;

  .btn--icon-text &,
  .card.text-center &,
  .footer & {
    margin-right: 0.25rem;
  }
}

// badge
.badge {
  line-height: unset;
}
